import { BackendApi } from "../backend-api";
import { ApiRoutes } from "../api-routes";
import { Token } from "domain/PaymentFlow/Payment/Invoice/utils/invoice.types";

export class PaymentsApi {
  public backendApi: BackendApi;

  constructor(backendUrl: string) {
    this.backendApi = new BackendApi(backendUrl);
  }

  public startTransaction(recordCheckId: string, email: string) {
    const startTransactionUrl = ApiRoutes.startTransaction(recordCheckId);
    const requestBody = this.buildGuestTransactionStartRequest(email);

    return this.backendApi.postRequest(startTransactionUrl, requestBody);
  }

  public startPackageTransaction(recordCheckUuid: string, email: string, packageName: string) {
    const startTransactionUrl = ApiRoutes.startPackageTransaction(recordCheckUuid, packageName);

    return this.backendApi.postRequest(startTransactionUrl);
  }

  public startPackageTransactionWithoutVin(packageName: string, lang: string, params: Record<string, string>) {
    const startTransactionUrl = ApiRoutes.startPackageTransactionWithoutVin(packageName, lang);

    return this.backendApi.postRequest(startTransactionUrl, {}, params);
  }

  public fetchPaymentMethods(transactionId: string) {
    return this.backendApi.request(ApiRoutes.paymentMethods(transactionId));
  }

  public postPaymentState(transactionId: string, body) {
    return this.backendApi.postRequest(ApiRoutes.pay(transactionId), body);
  }

  public postPaymentDetails(transactionId: string, body) {
    return this.backendApi.postRequest(ApiRoutes.paymentDetails(transactionId), body);
  }

  public postPaymentRefund(transactionId: string) {
    return this.backendApi.postRequest(ApiRoutes.automaticTransactionRefund(transactionId));
  }

  public fetchViesCheck(vatNumber: string) {
    return this.backendApi.request(ApiRoutes.viesCheck(vatNumber));
  }

  public putVoucherCode(transactionId: string, body, token: Token) {
    return this.backendApi.putRequest(ApiRoutes.voucherCode(transactionId), body, token);
  }

  public fetchVoucherCode(voucherCode: string, token: Token) {
    return this.backendApi.request(ApiRoutes.checkVoucherCode(voucherCode), token);
  }

  public fetchVoucherCodeWithPackageName(voucherCode: string, packageName: string, token: Token) {
    return this.backendApi.request(ApiRoutes.checkVoucherCodeWithPackageName(voucherCode, packageName), token);
  }

  public payForFullyDiscountedTransaction(transactionId: string) {
    return this.backendApi.postRequest(ApiRoutes.fullyDiscountedTransactionPayment(transactionId));
  }

  private buildGuestTransactionStartRequest(email: string) {
    return {
      email,
    };
  }
}
