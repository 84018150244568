import { EnvironmentVariables } from "utils/config/environment-variables";
import { Config } from "./config";

export function recaptchaLoader() {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      return reject();
    }

    if (window["grecaptcha"]) {
      return resolve(window["grecaptcha"]);
    }

    const scriptElement = loadRecaptchaScript();
    scriptElement.addEventListener("error", () => {
      return reject();
    });

    scriptElement.addEventListener("load", () => {
      window["grecaptcha"].enterprise.ready(() => resolve(window["grecaptcha"]));
    });
  });
}

function loadRecaptchaScript() {
  const recaptchaScript = document.createElement("script");
  const recaptchaKey = EnvironmentVariables.getGoogleReCaptchaV3SiteKey();

  recaptchaScript.src = `${Config.RECAPTCHA_JS_SCRIPT_URL}${recaptchaKey}&trustedtypes=true`;
  recaptchaScript.setAttribute("defer", "");
  recaptchaScript.setAttribute("async", "");
  document.head.appendChild(recaptchaScript);

  return recaptchaScript;
}
