import { ApiRoutes } from "../../../../utils/backend-api/api-routes";
import { BackendApi } from "../../../../utils/backend-api/backend-api";

export enum VhrStatus {
  ACTIVE = "ACTIVE",
  REFUNDED = "REFUNDED",
  REVOKED = "REVOKED",
}

export type Vhr = {
  makeAndModel: string;
  vinreg: string;
  encryptedVinreg: string;
  uuid: string;
  purchaseDate: Date;
  expiryDate: Date;
  riskFactorNumber: number;
  expired: boolean;
  status: VhrStatus;
};

export type ActiveUserPackage = {
  remainingCredits: number;
  purchasedCredits: number;
  price: number;
  expiresAt: string;
};

export interface DashboardData {
  activeReportsCount: number;
  expiredReportsCount: number;
  remainingCreditsCount: number;
  lastActiveUserPackage: ActiveUserPackage;
  reports: Vhr[];
  totalPages: number;
}

export interface CreditsData {
  activeReportsCount: number;
  expiredReportsCount: number;
  remainingCreditsCount: number;
  lastActiveUserPackage: ActiveUserPackage;
}

export interface ReportsData {
  content: Vhr[];
  totalPages: number;
}

export interface ReportsDataIncoming {
  content: Vhr[];
  page: {
    totalPages: number;
  };
}

export class DashboardApi {
  public backendApi: BackendApi;

  constructor(backendUrl: string) {
    this.backendApi = new BackendApi(backendUrl);
  }
  public async getDashboardCreditsData(): Promise<CreditsData> {
    const creditsData = await this.backendApi.request<CreditsData>(ApiRoutes.USER_DASHBOARD_CREDITS, null, null, "GET");
    return {
      lastActiveUserPackage: creditsData.lastActiveUserPackage,
      activeReportsCount: creditsData.activeReportsCount,
      expiredReportsCount: creditsData.expiredReportsCount,
      remainingCreditsCount: creditsData.remainingCreditsCount,
    };
  }

  public async getDashboardReportsData(pageNumber?: number, isExpired?: boolean): Promise<ReportsData> {
    const showExpired = isExpired ? "&expired=true" : "&expired=false";
    const reportsData = await this.backendApi.request<ReportsDataIncoming>(
      `${ApiRoutes.USER_DASHBOARD}&page=${pageNumber}${showExpired}`,
      null,
      null,
      "GET"
    );

    return {
      content: reportsData.content,
      totalPages: reportsData.page.totalPages,
    };
  }

  public async getDashboardData(pageNumber?: number, isExpired?: boolean): Promise<DashboardData> {
    const [creditsData, reportsData] = await Promise.all([
      this.getDashboardCreditsData(),
      this.getDashboardReportsData(pageNumber, isExpired),
    ]);
    return {
      lastActiveUserPackage: creditsData.lastActiveUserPackage,
      activeReportsCount: creditsData.activeReportsCount,
      expiredReportsCount: creditsData.expiredReportsCount,
      remainingCreditsCount: creditsData.remainingCreditsCount,
      reports: reportsData.content,
      totalPages: reportsData.totalPages,
    };
  }

  public async getRemainingCredits(): Promise<number> {
    return this.backendApi.request<number>(ApiRoutes.USER_REMAINING_CREDITS, null, null, "GET");
  }

  public static reportsReducer(data) {
    if (!data?.reports) {
      return [];
    }
    return data.reports.map((el) => ({
      makeAndModel: el.makeAndModel,
      vinreg: el.vinreg,
      encryptedVinreg: el.encryptedVinreg,
      uuid: el.uuid,
      purchaseDate: el.purchaseDate,
      expiryDate: el.expiryDate,
      riskFactorNumber: el.riskFactorNumber,
      expired: el.expired,
      status: el.status,
    }));
  }
}
