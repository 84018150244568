import { DashboardApi } from "domain/User/Account/utils/dashboard-api";
import { useStores } from "hooks";
import { useEffect, useRef, useState } from "react";
import { ApiConfig } from "utils/backend-api/api-config";
import { Log, buildLogEntityWithoutRequest, formatMessage } from "utils/log";

export enum Enabled {
  On = "on",
  Off = "off",
}

export const useCreditsCount = (enable = Enabled.On): number | null => {
  const [creditsAmount, setCreditsAmount] = useState<number | null>(null);
  const { userStore } = useStores();
  const isSubscribed = useRef(true);

  const fetchRemainingCredits = async () => {
    try {
      const dashboardApi = new DashboardApi(ApiConfig.buildBackendUrlForClient());
      const remainingCredits = await dashboardApi.getRemainingCredits();
      if (isSubscribed.current) {
        setCreditsAmount(remainingCredits);
      }
    } catch (error) {
      Log.error(buildLogEntityWithoutRequest(formatMessage(error), error));
    }
  };
  useEffect(() => {
    if (userStore.isAuthenticated && enable === Enabled.On) {
      fetchRemainingCredits();
    }
    return () => {
      isSubscribed.current = false;
    };
  }, [userStore.isAuthenticated]);

  return creditsAmount;
};
