import React, { useContext, useEffect, useRef, useState } from "react";
import { RecordCheckErrorMessages } from "utils/pricing-response/RecordCheckErrorMessages";
import RouterService from "domain/Routing/utils/router-service";
import { useTranslation } from "app";
import { useStores } from "hooks";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import useErrorPositioning from "./hooks/useErrorPositioning";
import isVinTooShort from "components/VinSearch/utils/isVinTooShort";
import { VinSearchContext } from "components/VinSearch/VinSearchContext";
import dynamic from "next/dynamic";
import { IProps } from "@carfax/web-ui/elements/VinSearch";
import { IVinErrorProps } from "components/VinSearch/VinError/VinError";
import { LanguageResolver } from "../../i18n/language-resolver";
import { withRecaptcha, WithRecaptchaInterface } from "domain/Recaptcha/withRecaptcha";
import { IPricingResponse } from "utils/pricing-response/types";
import BackendApiError from "utils/backend-api/backend-api-error";
import RecaptchaActions from "domain/Recaptcha/actions";
import { EHttpStatus } from "utils/backend-api/error-types";
import { languageConfig } from "i18n/language-config";
import { useCreditsCount } from "../../hooks/useCreditsCount";

import { dataLayerEvents } from "domain/SeoAndAnalytics/GoogleAnalytics/dataLayerEvents";

const VinSearchComponent = dynamic<IProps>(() =>
  import("@carfax/web-ui/elements/VinSearch").then((mod) => mod.VinSearch)
);

const VinSearchComponentWithoutContainer = dynamic<IProps>(() =>
  import("./VinSearchComponentWithoutContainer").then((mod) => mod.VinSearchComponentWithoutContainer)
);

const VinSearchComponentSquare = dynamic<IProps>(
  () => import("./VinSearchComponentSquare").then((mod) => mod.VinSearchComponentSquare),
  { ssr: false }
);

const VinSearchComponentSquareMobile = dynamic<IProps>(
  () => import("./VinSearchComponentSquareMobile").then((mod) => mod.VinSearchComponentSquareMobile),
  { ssr: false }
);

const VinError = dynamic<IVinErrorProps>(() => import("components/VinSearch/VinError"), { ssr: false });

export enum SearchDisplayType {
  Default = "default",
  Square = "square",
  SquareMobile = "squareMobile",
  DefaultWithoutContainer = "defaultWithoutContainer",
}

interface SearchOnLoadProps {
  errorMsgOnLoad?: string;
  vinOnLoad?: string;
}

interface ISearchProps {
  displayType?: SearchDisplayType;
  onLoadProps?: SearchOnLoadProps;
  onClose?: () => void;
  displayBelow?: boolean;
  autoFocus?: boolean;
  customPlaceholder?: string;
  withSearchButtonText?: string;
  previewPageSlug?: string;
}

const VinSearch: React.FC<ISearchProps> = ({
  displayType = SearchDisplayType.Default,
  displayBelow,
  onLoadProps,
  onClose = () => null,
  autoFocus = false,
  customPlaceholder,
  withSearchButtonText,
  previewPageSlug = "",
}) => {
  const { userStore, layoutStore, reportPricingStore, recordCheckStore, checkoutStore } = useStores();
  const { errorMsg, setErrorMsg, setHasFocus, routeToSingleReport } = useContext(VinSearchContext);
  const { ref, overMiddleSitePoint } = useErrorPositioning();
  const { t } = useTranslation(["common"]);
  const [defaultVin, setDefaultVin] = useState("");
  const routes = useRoutes();
  const emptyMessage = "";
  const language = LanguageResolver.getCurrentLanguageShort();
  const { toggles } = layoutStore;
  const [showLoader, setShowLoader] = useState(false);
  const recaptchaResolver = useRef<WithRecaptchaInterface>();
  const creditsCount = useCreditsCount();

  const onFocus = (state: boolean) => {
    if (!recaptchaResolver.current) {
      const recaptchaScript = withRecaptcha({ action: RecaptchaActions.vinsearch });
      recaptchaResolver.current = recaptchaScript;
    }
    setHasFocus(state);
  };

  const fetchPricingResponseForVin = async (vin: string): Promise<IPricingResponse> => {
    const PricingResponseApi = (await import("utils/pricing-response/pricing-api")).default;

    try {
      return await recaptchaResolver.current((tokens) => PricingResponseApi.fetchPricing(vin, { ...tokens }));
    } catch (error) {
      if (error instanceof BackendApiError) {
        if (error.status === EHttpStatus.BadRequest && error.msg) {
          throw error.msg;
        } else {
          throw PricingResponseApi.mapHttpCodeToErrorMessage(error.status);
        }
      }
      throw error;
    }
  };

  const handleSubmit = async (vin, inputRef) => {
    layoutStore.showReCaptchaBadge();

    if (isVinTooShort(vin)) {
      const locale = LanguageResolver.getCurrentLocale();
      if (languageConfig.isLocaleLicensePlateBased(locale)) {
        setErrorMsg(RecordCheckErrorMessages.INVALID_LP);
      } else {
        setErrorMsg(RecordCheckErrorMessages.INVALID_VIN);
      }
      return;
    }

    try {
      setShowLoader(true);
      const data = await fetchPricingResponseForVin(vin);
      reportPricingStore.setDataFromApi(data);
      recordCheckStore.setDataFromApi(data);
      checkoutStore.setDataFromApi(data);
      dataLayerEvents.vinSearchSuccessEvent(recordCheckStore.trackingData);

      onClose();
      redirectToPreview();
    } catch (errorMessage) {
      setShowLoader(false);
      setErrorMsg(errorMessage);
      inputRef.current.focus();
      dataLayerEvents.vinSearchErrorEvent();
    }
  };

  const redirectToPreview = () => {
    if (previewPageSlug) {
      redirectToCustomPreview();
    } else {
      let route;
      if (userStore.isAuthenticated && creditsCount > 0) {
        route = routes.redeemCredit;
      } else if (toggles["reportPreviewPage" + language.toUpperCase()]) {
        route = routes.reportPreview;
      } else {
        route = routes.preview;
      }
      RouterService.routeWithQuery(route, routeToSingleReport && { singleReport: true });
    }
  };

  const redirectToCustomPreview = () => {
    RouterService.routeTo(decodeURI(previewPageSlug));
  };

  const handleInputChange = (input: string) => {
    setErrorMsg(emptyMessage);
    setDefaultVin(input);
  };

  const renderSearch = () => {
    const searchProps = {
      placeholderMessage: customPlaceholder || t("vinSearch.inputPlaceholder"),
      error: errorMsg ? (
        <VinError
          vin={defaultVin}
          displayBelow={typeof displayBelow !== "undefined" ? displayBelow : overMiddleSitePoint}
        />
      ) : undefined,
      onSubmit: handleSubmit,
      onChange: (e) => handleInputChange(e.target.value),
      inputName: `vin-search-${displayType}`,
      testId: `vin-search-${displayType}`,
      handleFocus: onFocus,
      autoFocus: autoFocus,
      showLoader: showLoader,
      withSearchButtonText: withSearchButtonText,
      ariaLabelVinSearchButton: t("accessibility.vinSearchButton"),
    };

    switch (displayType) {
      case SearchDisplayType.Square:
        return <VinSearchComponentSquare {...searchProps} />;
      case SearchDisplayType.SquareMobile:
        return <VinSearchComponentSquareMobile {...searchProps} />;
      case SearchDisplayType.Default:
        return <VinSearchComponent {...searchProps} />;
      case SearchDisplayType.DefaultWithoutContainer:
        return <VinSearchComponentWithoutContainer {...searchProps} />;
    }
  };

  const { errorMsgOnLoad, vinOnLoad } = { ...onLoadProps };
  useEffect(() => {
    if (errorMsgOnLoad && vinOnLoad) {
      setErrorMsg(errorMsgOnLoad);
      setDefaultVin(vinOnLoad);
    }
  }, [errorMsgOnLoad]);

  return <div ref={ref}>{renderSearch()}</div>;
};
export default VinSearch;
