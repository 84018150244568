import { EnvironmentVariables } from "utils/config/environment-variables";
import { recaptchaLoader } from "./recaptcha-loader";
import BackendApiError from "../../utils/backend-api/backend-api-error";
import { TransactionErrorMessages } from "domain/PaymentFlow/DropIn/utils/transactionService";

const SHOW_ATTRIBUTE = "data-captcha-show";
const CAPTCHA_ID_ATTRIBUTE = "data-grecaptcha-id";

class RecaptchaService {
  public recaptchaKey: string;
  public recaptchaScript: HTMLScriptElement = null;
  public googleRecaptcha: Promise<any>;
  public static widgetId: string;

  constructor() {
    this.recaptchaKey = EnvironmentVariables.getGoogleReCaptchaV3SiteKey();
  }

  public async initService() {
    if (typeof window === "undefined") {
      return;
    }
    if (window["grecaptcha"]) {
      this.googleRecaptcha = Promise.resolve(window["grecaptcha"]);
    } else {
      this.googleRecaptcha = recaptchaLoader();
    }
  }

  public async resetWidget(captchaId: string) {
    const recaptchaInstance = await this.googleRecaptcha;
    const captchaElement = document.getElementById(captchaId);

    if (recaptchaInstance && captchaElement) {
      captchaElement.setAttribute(SHOW_ATTRIBUTE, "");
      const widgetId = captchaElement.getAttribute("data-grecaptcha-id");
      if (widgetId) {
        recaptchaInstance.enterprise.ready(() => {
          recaptchaInstance.enterprise.reset(widgetId);
        });
      }
    }
  }

  public async resolveRecaptchaV3(action: string): Promise<string> {
    this.initService();
    const recaptchaInstance = await this.googleRecaptcha;
    return recaptchaInstance.enterprise.execute(this.recaptchaKey, { action });
  }

  public async resolveRecaptchaV2(captchaId: string): Promise<string> {
    this.initService();
    const recaptchaInstance = await this.googleRecaptcha;
    try {
      const widgetId = document.getElementById(captchaId).getAttribute("data-grecaptcha-id");
      return await recaptchaInstance.enterprise.getResponse(widgetId);
    } catch {
      try {
        return await this.renderManualCaptcha(recaptchaInstance, captchaId);
      } catch {
        throw new BackendApiError("Error while solving reCAPTCHA", 403);
      }
    }
  }
  private renderManualCaptcha(recaptchaInstance, captchaId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const captchaElement = document.getElementById(captchaId);
      captchaElement.setAttribute(SHOW_ATTRIBUTE, "");
      recaptchaInstance.enterprise.ready(() => {
        const widgetId = recaptchaInstance.enterprise.render(captchaId, {
          sitekey: EnvironmentVariables.getGoogleReCaptchaSiteIdSalesForce(),
          callback: (receivedToken: string) => {
            captchaElement.setAttribute(CAPTCHA_ID_ATTRIBUTE, widgetId);
            captchaElement.removeAttribute(SHOW_ATTRIBUTE);
            resolve(receivedToken);
          },
          "error-callback": () => {
            reject(TransactionErrorMessages.RECAPTCHA);
          },
          "expired-callback": () => {
            captchaElement.setAttribute(SHOW_ATTRIBUTE, "");
            recaptchaInstance.enterprise.reset(widgetId);
            reject(TransactionErrorMessages.RECAPTCHA);
          },
          isolated: true,
        });
      });
    });
  }
}

export { RecaptchaService };
