import BackendApiError from "utils/backend-api/backend-api-error";
import { EApiRequestMessage, EHttpStatus } from "utils/backend-api/error-types";
import { RecaptchaService } from "domain/Recaptcha/recaptcha-handlers";

const GENERAL_CAPTCHA_CONTAINER = "vinsearch-recaptcha";
interface Token {
  "g-recaptcha-token"?: string;
  recaptchaToken?: string;
}
export type WithRecaptchaProps = { action: string; captchaId?: string };
export type WithRecaptchaCallback<T> = (token: Token) => Promise<T>;
export type WithRecaptchaInterface = <T>(cb: WithRecaptchaCallback<T>) => Promise<T>;

export function withRecaptcha({
  action,
  captchaId = GENERAL_CAPTCHA_CONTAINER,
}: WithRecaptchaProps): WithRecaptchaInterface {
  const RecaptchaServiceInstance = new RecaptchaService();

  async function showV2<T>(captchaId: string, callback: WithRecaptchaCallback<T>) {
    const v2Token = await RecaptchaServiceInstance.resolveRecaptchaV2(captchaId);
    if (!v2Token) {
      throw new BackendApiError(EApiRequestMessage.INVALID_CAPTCHA, 403);
    }
    try {
      return await callback({ "g-recaptcha-token": v2Token });
    } catch (errorV2) {
      if (errorV2 instanceof BackendApiError && isRecaptchaError(errorV2)) {
        RecaptchaServiceInstance.resetWidget(captchaId);
      }
      throw errorV2;
    }
  }

  return async function (callback) {
    try {
      const v3Token = await RecaptchaServiceInstance.resolveRecaptchaV3(action);
      const res = await callback({ recaptchaToken: v3Token });
      return res;
    } catch (error) {
      if (error instanceof BackendApiError && isRecaptchaError(error)) {
        return await showV2(captchaId, callback);
      }
      throw error;
    }
  };
}
function isRecaptchaError(error: BackendApiError) {
  return error.status === EHttpStatus.Forbidden && error.msg === EApiRequestMessage.INVALID_CAPTCHA;
}
