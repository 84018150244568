import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const options = {
  rootMargin: "-45%",
  threshold: 0.1,
};

export interface ErrorPositioning {
  ref: (Element) => void;
  overMiddleSitePoint: boolean;
}

export default function useErrorPositioning(): ErrorPositioning {
  const [overMiddleSitePoint, setOverMiddleSitePoint] = useState(false);
  const { ref, entry } = useInView(options);

  useEffect(() => {
    if (entry?.intersectionRect.top < window.innerHeight / 2) {
      setOverMiddleSitePoint(true);
    } else {
      setOverMiddleSitePoint(false);
    }
  }, [entry]);

  return { ref, overMiddleSitePoint };
}
