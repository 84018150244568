import { withRecaptcha } from "domain/Recaptcha/withRecaptcha";
import { CheckoutStore } from "stores/checkout.store";
import { ProductType } from "types/stores/checkout.types";
import { ApiConfig } from "utils/backend-api/api-config";
import BackendApiError from "utils/backend-api/backend-api-error";
import { EHttpStatus } from "utils/backend-api/error-types";
import { PaymentsApi } from "utils/backend-api/payment/payments-api";

interface TransactionDto {
  uuid: string;
  status: string;
}

export enum TransactionErrorMessages {
  RECAPTCHA = "recaptcha",
  DEFAULT = "default",
}

class TransactionService {
  public async startTransaction(recordCheckUuid: string, checkoutStore: CheckoutStore, email: string) {
    let transactionDto;
    const paymentsApi = new PaymentsApi(ApiConfig.buildBackendUrlForClient());

    if (ProductType.creditsPackage === checkoutStore.product.type) {
      transactionDto = (await paymentsApi.startPackageTransaction(
        recordCheckUuid,
        email,
        checkoutStore.product.id
      )) as TransactionDto;
    } else {
      transactionDto = (await paymentsApi.startTransaction(recordCheckUuid, email)) as TransactionDto;
    }

    checkoutStore.setTransaction(transactionDto.uuid, email);
    return transactionDto;
  }

  public async startTransactionWithoutVin(checkoutStore: CheckoutStore, lang: string, params: Record<string, string>) {
    const paymentsApi = new PaymentsApi(ApiConfig.buildBackendUrlForClient());

    const transactionDto = (await paymentsApi.startPackageTransactionWithoutVin(
      checkoutStore.product.id,
      lang,
      params
    )) as TransactionDto;

    checkoutStore.setTransaction(transactionDto.uuid, checkoutStore.user.email);
    return transactionDto;
  }

  public async fetchStartTransactionWithoutVin(
    checkoutStore: CheckoutStore,
    lang: string,
    action: string,
    captchaId?: string
  ): Promise<TransactionDto> {
    try {
      return await withRecaptcha({ action, captchaId })<TransactionDto>((tokens) =>
        this.startTransactionWithoutVin(checkoutStore, lang, {
          ...tokens,
        })
      );
    } catch (error) {
      if (error instanceof BackendApiError) {
        throw this.mapHttpCodeToErrorMessage(error.status);
      }
      throw error;
    }
  }

  public mapHttpCodeToErrorMessage(statusError: number): any {
    switch (statusError) {
      case EHttpStatus.Forbidden:
        return TransactionErrorMessages.RECAPTCHA;
      default:
        return TransactionErrorMessages.DEFAULT;
    }
  }
}

export default new TransactionService();
export { TransactionService };
