const RecaptchaActions = {
  vinsearch: "vinsearch",
  login: "login",
  signIn: "createAccount",
  payment: "adyenPayment",
  invoice: "invoice_user",
  partner: "partner",
  voucher: "voucher",
  passwordReset: "password_reset",
  setPassword: "set_password",
};

export default RecaptchaActions;
